import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import PGCR from "./pgcr";
import PGCRSearch from "./PGCRSearch";
import FriendFinder from "./FriendFinder";
import Callback from "./Callback";
import './App.css';

const API_KEY = '30ac6692ced14a55b3f6a2862093e021';
const BUNGIE_ENDPOINT = "https://www.bungie.net";
const BUNGIE_ENDPOINT_PGCRS = "https://stats.bungie.net";

const root = ReactDOM.createRoot(document.getElementById('root'));
let appVars = require("../package.json");

var activities = localStorage.getItem("activityHashes");
if (activities === null){
  fetch(`${BUNGIE_ENDPOINT}/Platform/Destiny2/Manifest`, { headers: {"X-API-Key": API_KEY} })
    .then(response => response.json())
    .then(item => fetch(`${BUNGIE_ENDPOINT}${item.Response.jsonWorldComponentContentPaths.en["DestinyActivityDefinition"]}`))
    .then(response => response.json())
    .then(item => {
      console.log(item);
      activities = {};
      for (var act in item){
        activities[item[act].hash] = {actName: item[act].originalDisplayProperties.name, actSubName: item[act].displayProperties.name, image: item[act].pgcrImage, maxPlayers: item[act].matchmaking !== undefined ? item[act].matchmaking.maxParty : 0};
      }
      console.log(activities);
      localStorage.setItem("activityHashes", JSON.stringify(activities));
    });
}

function RenderFooter() {
  return <div className="footer">
    <br></br>
    <p>{appVars.name} {appVars.version} - Created by <a href="https://twitter.com/OatsFX" target="_blank">@OatsFX</a> - All media and images rightfully owned by Bungie</p>
  </div>
}

root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="" element={<App />} />
        <Route path="/pgcr/:pgcrId/" element={<PGCR />} />
        <Route path="/pgcr/" element={<PGCRSearch />} />
        <Route path="/friend-finder/" element={<FriendFinder />} />
        <Route path="/callback/" element={<Callback />} />
      </Routes>
    </BrowserRouter>
    <RenderFooter />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
