import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import './App.css';

const CLIENT_ID = "36496";
// Beta id: 43113
// ACtual id: 36496
const CLIENT_SECRET = "nnnILwSMS.pz7nXfe5Ncoqg8i73rwiz.Bw3UGzXOyOU";
// Beta secret: j6.40J3puOneZ62lA0yLGTXqQagvESRiHRYBJkiJhYc
// Actual secret: nnnILwSMS.pz7nXfe5Ncoqg8i73rwiz.Bw3UGzXOyOU
const API_KEY = '30ac6692ced14a55b3f6a2862093e021';
// Beta key: 34d3ba0e2ae9494daffdcd6901133748
// Actual key: 30ac6692ced14a55b3f6a2862093e021
const BUNGIE_ENDPOINT = "https://www.bungie.net";
const BUNGIE_ENDPOINT_PGCRS = "https://stats.bungie.net";

const Callback = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [success, setSuccess] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  const HandleCode = async () => {
    var code = searchParams.get("code");

    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Basic ${btoa(CLIENT_ID+":"+CLIENT_SECRET)}`,
      }),
      body: `grant_type=authorization_code&code=${code}`
    };
    const response = await fetch(`${BUNGIE_ENDPOINT}/Platform/App/OAuth/Token`, requestOptions);
    console.log(response);
    const item = await response.json();
    console.log(item);

    if (item.error === undefined){
      // Success
      localStorage.setItem("accessData", JSON.stringify(item));
      const accountResponse = await fetch(`${BUNGIE_ENDPOINT}/Platform/Destiny2/-1/Profile/${item.membership_id}/LinkedProfiles/?getAllMemberships=true`, { headers: {"X-API-Key": API_KEY} });
      const accountItem = await accountResponse.json();
      let goodProfile = {};
      console.log(accountItem);
      accountItem.Response.profiles.forEach((profile) => {
        if (profile.isCrossSavePrimary){
          localStorage.setItem('activeProfile', JSON.stringify(profile));
          goodProfile = profile;
          console.log(profile);
        }
      });
      const profResponse = await fetch(`${BUNGIE_ENDPOINT}/Platform/Destiny2/${goodProfile.membershipType}/Profile/${goodProfile.membershipId}/?components=100,200`, { headers: {"X-API-Key": API_KEY} });
      const profItem = await profResponse.json();
      console.log(profItem);
      localStorage.setItem('activeCharacters', JSON.stringify(profItem.Response));
      setSuccess(true);
    }
    else{
      setSuccess(false);
    }
    setIsFinished(true);
  }
  
  useEffect(() => {
    HandleCode();
  }, []);

    return (
      <div className="app">
        <div className="head">
          {
            isFinished ? (success ? <h1>Linking Success</h1> : <h1>Linking Failure</h1>) : <h1>Loading...</h1>
          }
        </div>
        {
          isFinished ? <h2>You can safely return to the home page.</h2> : <h2>One sec...</h2>
        }
      </div>
    );
  }
  
  export default Callback;