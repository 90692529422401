import { render } from '@testing-library/react';
import { hasSelectionSupport } from '@testing-library/user-event/dist/utils';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import GuardianCard from './GuardianCard';

import './App.css';
import PGCRCard from './PGCRCard';
import { border } from '@mui/system';
import { act } from 'react-dom/test-utils';

const DestinyClass = {
  0: "Titan",
  1: "Hunter",
  2: "Warlock",
}

const API_KEY = '30ac6692ced14a55b3f6a2862093e021';
const BUNGIE_ENDPOINT = "https://www.bungie.net";
const BUNGIE_ENDPOINT_PGCRS = "https://stats.bungie.net";

var activeChar;

const PGCRSearch = () => {
  var prof = JSON.parse(localStorage.getItem("activeProfile"));
  var chars = JSON.parse(localStorage.getItem("activeCharacters"));
  
  const [activeChar, setActiveChar] = useState(chars.profile.data.characterIds[0]);

  const [pgcrList, setPgcrList] = useState([]);
  
  const [page, setPage] = useState(0);
  const [characters, setCharacters] = useState(chars);

  const [filter, setFilter] = useState(0);
  const [profile, setProfile] = useState(prof);

  const [isLoading, setIsLoading] = useState(true);

  const getPgcrList = async (PAGE, CHAR_ID) => {
    setIsLoading(true);
    
    const response = await fetch(`${BUNGIE_ENDPOINT}/Platform/Destiny2/${profile.membershipType}/Account/${profile.membershipId}/Character/${CHAR_ID}/Stats/Activities/?count=25&page=${PAGE}&mode=${filter}`, { headers: {"X-API-Key": API_KEY} });
    const item = await response.json();
    console.log(item);
    setPgcrList(item.Response.activities);
    setIsLoading(false);
  }

  const updatePage = async (TYPE) => {
    var curPage = page;
    console.log(activeChar);
    if (TYPE === 0){
      setPage((prevPage) => prevPage <= 0 ? 0 : prevPage - 1);
      curPage = curPage <= 0 ? 0 : curPage - 1;
    }
    else{
      setPage((prevPage) => prevPage + 1);
      curPage++;
    }
    await getPgcrList(curPage, activeChar);
  }

  const updateChar = async (CharId) => {
    setActiveChar(CharId);
    console.log(activeChar);
    await getPgcrList(page, CharId);
  }

  useEffect(() => {
    getPgcrList(page, activeChar);
  }, []);

  function RenderSearch(){
    if (isLoading){
      return <>
        <h1>Loading...</h1>
      </>
    }
    return <>
    <h2 className="name">Whatcha Looking For?</h2>
    <h3>Here is a list of your PGCRs.</h3>
    <div className="char-button-container">
      {
        (
          characters.profile.data.characterIds.map((charId) => (
            <a onClick={() => {updateChar(charId)}} id={charId} >
              <div className="guardian" style={{backgroundImage: `url("${BUNGIE_ENDPOINT+characters.characters.data[charId].emblemBackgroundPath}")`, border: charId === activeChar ? '4px solid #afa' : ''}} >
                <div className="guardian-details">
                  <h2>{DestinyClass[characters.characters.data[charId].classType]}</h2>

                  <p style={{opacity: 0.6}}>{characters.characters.data[charId].titleRecordHash}</p>
                </div>
                
                <div className="guardian-light">
                  <h3>{characters.characters.data[charId].light}</h3>
                </div>
              </div>
            </a>
          ))
        )
      }
    </div>
    <div className="page-button-container">
      <button id="page-decrease" onClick={() => {updatePage(0)}}>-</button>
      <p>{page + 1}</p>
      <button id="page-increase" onClick={() => {updatePage(1)}}>+</button>
    </div>
    <div className="pgcr-search-container">
      {
      (
        pgcrList.map((pgcr) => (
          <PGCRCard pgcr={pgcr} />
        ))
      )
      }
    </div></>
  }

  return (
    <div className="app">
      <div className="head">
        <h1>OatsFX's PGCR Search</h1>
      </div>
      <RenderSearch />
    </div>
  );
}



export default PGCRSearch;