import React, { useEffect, useState } from 'react';

const BUNGIE_ENDPOINT = "https://www.bungie.net";

const FriendPlayerCard = ({ player, occurrances }) => {
  const tag = `${player.bungieGlobalDisplayNameCode}`;

  return (
      <div className="friend-card">
        <img src={BUNGIE_ENDPOINT+player.emblemPath} className="emblem"></img>
        
        <div className="details">
          <h3>{player.bungieGlobalDisplayName}<span className="bungie-tag">#{tag.padStart(4 ,'0')}</span></h3>
        </div>

        <div className="stats">
          <div className="stat">
            <p>{occurrances}</p><p className="stat-name">MATCHES</p>
          </div>
        </div>
      </div>
  )
}
export default FriendPlayerCard;