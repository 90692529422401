import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const BUNGIE_ENDPOINT = "https://www.bungie.net";

const prettyTime = (period) => {
  var parsed = new Date(period);
  var diff = Math.abs(new Date() - parsed);
  var days = Math.floor(diff / (1000 * 60 * 60 * 24));
  diff -=  days * (1000 * 60 * 60 * 24);

  var hours = Math.floor(diff / (1000 * 60 * 60));
  diff -= hours * (1000 * 60 * 60);

  var mins = Math.floor(diff / (1000 * 60));
  diff -= mins * (1000 * 60);

  var seconds = Math.floor(diff / (1000));
  diff -= seconds * (1000);

  if (days > 0){
    return `${parsed.getMonth()+1}/${parsed.getDate()}/${parsed.getFullYear()}`;
  }
  else{
    return `${hours}hr${hours === 1 ? "" : "s"} ${mins}min${mins === 1 ? "" : "s"} ago`;
  }
}

const PGCRCard = ({ pgcr }) => {

  const acts = JSON.parse(localStorage.getItem("activityHashes"));

  return (
    <Link to={`/pgcr/${pgcr.activityDetails.instanceId}`} className="pgcr-link">
      <div className="pgcr-entry" id={pgcr.activityDetails.instanceId}>
      <img src={BUNGIE_ENDPOINT+acts[pgcr.activityDetails.referenceId].image} className="image"></img>
        
        <div className="details">
          <h3>{acts[pgcr.activityDetails.referenceId].actName}</h3>
          <p>{acts[pgcr.activityDetails.directorActivityHash].actName.replace(acts[pgcr.activityDetails.referenceId].actName, "")}</p>
        </div>

        <div className="date">
          {prettyTime(pgcr.period)}
        </div>
      </div>
    </Link>
      
  )
}
export default PGCRCard;