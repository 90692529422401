import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import PGCR from "./pgcr";

import './App.css';
import GuardianCard from './GuardianCard';
import { Button } from '@mui/material';

const API_KEY = '30ac6692ced14a55b3f6a2862093e021';
const BUNGIE_ENDPOINT = "https://www.bungie.net";
var components = "100,200";

function App(){
  const [guardians, setGuardians] = useState([]);
  const [charIds, setCharIds] = useState([]);

  const [profile, setProfile] = useState({});

  const getCharacters = async (memType, memId) => {
    const response = await fetch(`${BUNGIE_ENDPOINT}/Platform/Destiny2/${memType}/Profile/${memId}/?components=${components}`, { headers: {"X-API-Key": API_KEY} });
    const item = await response.json();

    console.log(item);
    setGuardians(item.Response.characters.data);
    setCharIds(item.Response.profile.data.characterIds);
  }

  const getLocals = async () => {
    const acts = JSON.parse(localStorage.getItem("activityHashes"));

    var profile = JSON.parse(localStorage.getItem("activeProfile"));

    if (profile === null){
      console.log("No active profile found in local.");
    }
    else{
      setProfile(profile);
      console.log(profile);
    }

    await getCharacters(profile.membershipType, profile.membershipId);
  }

  const link = async () => {
    window.open("https://www.bungie.net/en/OAuth/Authorize?client_id=36496&response_type=code");

    // 43113
    // 36496
  }

  useEffect(() => {
    getLocals();
  }, []);

  return (
    <div className="app">
      <div className="head">
        <h1>Look it's your Guardians...</h1>
      </div>

      <div>
        <button onClick={link}>Link</button>
        <Link to={`/pgcr/`} className="pgcr-link"><button>PGCR Search</button></Link>
      </div>
      
      
      <div className="mid">
        <div className="search">
          <input 
            placeholder="Search Guardian#0000"
          />
        </div>
      </div>

      <div className="guardian-container">
        <h2 className="name">{profile.bungieGlobalDisplayName}<span className="bungie-tag">#{profile.bungieGlobalDisplayNameCode}</span></h2>
        {charIds.map((charId) => (
          <GuardianCard guardian={guardians[charId]} />
        ))}
      </div>
    </div>
  );
}

export default App;
