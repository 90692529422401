import { render } from '@testing-library/react';
import { hasSelectionSupport } from '@testing-library/user-event/dist/utils';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu";
import FriendPlayerCard from "./FriendPlayerCard";

import bg1 from "./images/bg1-min.png";
import bg2 from "./images/bg2-min.png";
import bg3 from "./images/bg3-min.png";
import bg4 from "./images/bg4-min.png";
import bg5 from "./images/bg5-min.png";
import bg6 from "./images/bg6-min.png";
import bg7 from "./images/bg7-min.png";
import bg8 from "./images/bg8-min.png";

import './App.css';
import PGCRCard from './PGCRCard';
import PGCRPlayerCard from './PGCRPlayerCard';
import { color } from '@mui/system';

const API_KEY = '30ac6692ced14a55b3f6a2862093e021';
// Beta key: 34d3ba0e2ae9494daffdcd6901133748
// Actual key: 30ac6692ced14a55b3f6a2862093e021
const BUNGIE_ENDPOINT = "https://www.bungie.net";
const BUNGIE_ENDPOINT_PGCRS = "https://stats.bungie.net";

const bgImages = [
  bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8,
];

const imageIndex = Math.floor(Math.random() * bgImages.length);

var players = JSON.parse(localStorage.getItem("ffPlayers"));
if (players === null){
  players = [];
}
console.log(players);
var oldPgcrIds = JSON.parse(localStorage.getItem("ffPgcrsParsed"));
if (oldPgcrIds === null){
  oldPgcrIds = [];
}
console.log(oldPgcrIds);

var lastIndex = JSON.parse(localStorage.getItem("ffLastIndex"));

const acts = JSON.parse(localStorage.getItem("activityHashes"));
console.log(acts);

const FriendFinder = () => {
  const PGCR_PER_PAGE = 50;

  const profile = JSON.parse(localStorage.getItem("activeProfile"));
  const characters = JSON.parse(localStorage.getItem("activeCharacters"));
  
  const [top10All, setTop10All] = useState([]);
  const [top10Raid, setTop10Raid] = useState([]);
  const [top10Pvp, setTop10Pvp] = useState([]);
  const [top10Gambit, setTop10Gambit] = useState([]);
  const [top10Gms, setTop10Gms] = useState([]);

  var pgcrsFound = oldPgcrIds.length;

  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(`Hit the Begin button to start fetching. PGCRs Loaded: ${pgcrsFound}`);

  const BeginPGCRIndexing = async () => {
    setLoadingStatus("Fetching Character IDs (including deleted characters)...");
    var charIds = [];
    for (const id of characters.profile.data.characterIds){
      charIds.push(id);
    }
    const response = await fetch(`${BUNGIE_ENDPOINT_PGCRS}/Platform/Destiny2/${profile.membershipType}/Account/${profile.membershipId}/Stats/?groups=1`, { headers: {"X-API-Key": API_KEY} });
    const item = await response.json();
    for (const character of item.Response.characters){
      if (!charIds.some(charId => charId === character.characterId)){
        charIds.push(character.characterId);
      }
    }
    console.log(charIds);
    for (const character of charIds){
      var currentPage = 0;
      pgcrsFound = 0;
      console.warn(`New Character Started: ${character}. Deleted? ${character.deleted}`);
      while (true){
        const pgcrList = await getPgcrList(currentPage, character);
        //console.log(pgcrList);
        setLoadingStatus(`Fetching PGCRs... (${pgcrsFound}) This will take some time if this is your first time.`);
        for (const activity of pgcrList){
          if (oldPgcrIds.includes(activity.activityDetails.instanceId)){
            // We've already fetched this PGCR, no need to do it again!
            //console.log(`Duplicate found: ${activity.activityDetails.instanceId}`);
            continue;
          }
          //console.log(`Fetching ${activity.activityDetails.instanceId}`);
          // We haven't fetched this PGCR yet...
          const response = await fetch(`${BUNGIE_ENDPOINT_PGCRS}/Platform/Destiny2/Stats/PostGameCarnageReport/${activity.activityDetails.instanceId}`, { headers: {"X-API-Key": API_KEY} });
          const item = await response.json();
          var currentPlayers = [];
          await item.Response.entries.forEach(async (entry) => {
            if (currentPlayers.some(player => player === entry.player.destinyUserInfo.membershipId) || profile.membershipId === entry.player.destinyUserInfo.membershipId){
              return;
            }

            if (!entry.player.destinyUserInfo.bungieGlobalDisplayName){
              return;
            }

            let player = {
              emblemPath: entry.player.destinyUserInfo.iconPath, 
              bungieGlobalDisplayName: entry.player.destinyUserInfo.bungieGlobalDisplayName,
              bungieGlobalDisplayNameCode: entry.player.destinyUserInfo.bungieGlobalDisplayNameCode,
              membershipId: entry.player.destinyUserInfo.membershipId,
              occurrances: 0,
              raidOccurances: 0,
              pvpOccurrances: 0,
              gambitOccurrances: 0,
              gmOccurrances: 0,
            }
            players.push(player);

            var index = players.findIndex(player => player.membershipId === entry.player.destinyUserInfo.membershipId);
            players[index].occurrances++;

            // Raid
            if (activity.activityDetails.modes.some(mode => mode === 4)){
              players[index].raidOccurances++;
            }
            // PvP
            else if (activity.activityDetails.modes.some(mode => mode === 5)){
              players[index].pvpOccurrances++;
            }
            // Gambit
            else if (activity.activityDetails.modes.some(mode => mode === 63 || mode === 75)){
              players[index].gambitOccurrances++;
            }
            // Grandmasters
            else if (acts[activity.activityDetails.directorActivityHash].actName.includes("Grandmaster")){
              players[index].gmOccurrances++;
            }
            currentPlayers.push(entry.player.destinyUserInfo.membershipId);
          });
          oldPgcrIds.push(activity.activityDetails.instanceId);
        }
        setTop10All(players.filter((a) => a.membershipId !== profile.membershipId).sort((a, b) => b.occurrances - a.occurrances).slice(0, 10));
        setTop10Raid(players.filter((a) => a.membershipId !== profile.membershipId).sort((a, b) => b.raidOccurances - a.raidOccurances).slice(0, 10));
        setTop10Pvp(players.filter((a) => a.membershipId !== profile.membershipId).sort((a, b) => b.pvpOccurrances - a.pvpOccurrances).slice(0, 10));
        setTop10Gambit(players.filter((a) => a.membershipId !== profile.membershipId).sort((a, b) => b.gambitOccurrances - a.gambitOccurrances).slice(0, 10));
        setTop10Gms(players.filter((a) => a.membershipId !== profile.membershipId).sort((a, b) => b.gmOccurrances - a.gmOccurrances).slice(0, 10));

        if (pgcrList.length < PGCR_PER_PAGE){
          pgcrsFound += pgcrList.length;
          break;
        }
        pgcrsFound += PGCR_PER_PAGE;
        try{
          localStorage.setItem('ffPlayers', JSON.stringify(players));
          localStorage.setItem('ffPgcrsParsed', JSON.stringify(oldPgcrIds));
        }
        catch{
          console.log('Likely, thing is full.')
        }
        lastIndex = new Date();
        localStorage.setItem('ffLastIndex', JSON.stringify(lastIndex));
        currentPage++;
      }
    }
    setLoadingStatus(`Done! PGCRs fetched: ${pgcrsFound}.`);
    setLoading(false);
  }
  

  const getPgcrList = async (PAGE, CHAR_ID) => {
    const response = await fetch(`${BUNGIE_ENDPOINT}/Platform/Destiny2/${profile.membershipType}/Account/${profile.membershipId}/Character/${CHAR_ID}/Stats/Activities/?count=${PGCR_PER_PAGE}&page=${PAGE}&mode=0`, { headers: {"X-API-Key": API_KEY} });
    const item = await response.json();

    return item.Response.activities;
  }

  useEffect(() => {
    if (players.length > 0){
      setTop10All(players.filter((a) => a.membershipId !== profile.membershipId).sort((a, b) => b.occurrances - a.occurrances).slice(0, 10));
      setTop10Raid(players.filter((a) => a.membershipId !== profile.membershipId).sort((a, b) => b.raidOccurances - a.raidOccurances).slice(0, 10));
      setTop10Pvp(players.filter((a) => a.membershipId !== profile.membershipId).sort((a, b) => b.pvpOccurrances - a.pvpOccurrances).slice(0, 10));
      setTop10Gambit(players.filter((a) => a.membershipId !== profile.membershipId).sort((a, b) => b.gambitOccurrances - a.gambitOccurrances).slice(0, 10));
      setTop10Gms(players.filter((a) => a.membershipId !== profile.membershipId).sort((a, b) => b.gmOccurrances - a.gmOccurrances).slice(0, 10));
    }
  }, []);

  function RenderFinder () {
    return <>
      <div className="button-container">
        <button id="sort-kills" onClick={BeginPGCRIndexing}>Begin</button>
      </div>
      <div className="top-container">
        <div className="title-container">
          <h2 className="name">{profile.bungieGlobalDisplayName}#{profile.bungieGlobalDisplayNameCode}</h2>
          <h3 className="sub-name">Plays on Steam.</h3>
        </div>
      </div>
      <h2>{loadingStatus}</h2>
      <p>Last Indexed: {prettyTime(lastIndex)}</p>
      <div className="container">
        <div className="matches-container">
          <h2>All Activity Types</h2>
          {
            (
              top10All.map((player) => (
                player.occurrances > 0 ? <FriendPlayerCard player={player} occurrances={player.occurrances} /> : <></>
              ))
            )
          }
        </div>

        <div className="matches-container">
          <h2>Raids</h2>
          {
            (
              top10Raid.map((player) => (
                player.raidOccurances > 1 ? <FriendPlayerCard player={player} occurrances={player.raidOccurances} /> : <></>
              ))
            )
          }
        </div>

        <div className="matches-container">
          <h2>PvP</h2>
          {
            (
              top10Pvp.map((player) => (
                player.pvpOccurrances > 1 ? <FriendPlayerCard player={player} occurrances={player.pvpOccurrances} /> : <></>
              ))
            )
          }
        </div>

        <div className="matches-container">
          <h2>Gambit</h2>
          {
            (
              top10Gambit.map((player) => (
                player.gambitOccurrances > 1 ? <FriendPlayerCard player={player} occurrances={player.gambitOccurrances} /> : <></>
              ))
            )
          }
        </div>

        <div className="matches-container">
          <h2>Grandmaster Nightfalls</h2>
          {
            (
              top10Gms.map((player) => (
                player.gmOccurrances > 1 ? <FriendPlayerCard player={player} occurrances={player.gmOccurrances} /> : <></>
              ))
            )
          }
        </div>
      </div>
    </>
  }

  return (
    <div className="app" style={{"backgroundImage":`linear-gradient(to bottom, #14141445, #141414), url(\'${bgImages[imageIndex]}\')`}}>
      <div className="head">
        <h1>Who's your Destiny 2 best friend?</h1>
      </div>
      <RenderFinder />
    </div>
  );
}

const prettyTime = (period) => {
  var parsed = new Date(period);
  var diff = Math.abs(new Date() - parsed);
  var days = Math.floor(diff / (1000 * 60 * 60 * 24));
  diff -=  days * (1000 * 60 * 60 * 24);

  var hours = Math.floor(diff / (1000 * 60 * 60));
  diff -= hours * (1000 * 60 * 60);

  var mins = Math.floor(diff / (1000 * 60));
  diff -= mins * (1000 * 60);

  var seconds = Math.floor(diff / (1000));
  diff -= seconds * (1000);

  if (days > 0){
    return `${parsed.getMonth()+1}/${parsed.getDate()}/${parsed.getFullYear()}`;
  }
  else{
    return `${hours}hr${hours === 1 ? "" : "s"} ${mins}min${mins === 1 ? "" : "s"} ago`;
  }
}

export default FriendFinder;