import React from 'react';
const BUNGIE_ENDPOINT = "https://www.bungie.net";
const DestinyClass = {
  0: "Titan",
  1: "Hunter",
  2: "Warlock",
}

const GuardianCard = ({ guardian }) => {
  return (
    <div className="guardian" style={{backgroundImage: `url("${BUNGIE_ENDPOINT+guardian.emblemBackgroundPath}")`}}>
      <div className="guardian-details">
        <h2>{DestinyClass[guardian.classType]}</h2>

        <p style={{opacity: 0.6}}>{guardian.titleRecordHash}</p>
      </div>
      
      <div className="guardian-light">
        <h3>{guardian.light}</h3>
      </div>
    </div>
  )
}
export default GuardianCard;