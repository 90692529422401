import { render } from '@testing-library/react';
import { hasSelectionSupport } from '@testing-library/user-event/dist/utils';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu";

import './App.css';
import PGCRCard from './PGCRCard';
import PGCRPlayerCard from './PGCRPlayerCard';
import { color } from '@mui/system';

const API_KEY = '30ac6692ced14a55b3f6a2862093e021';
const BUNGIE_ENDPOINT = "https://www.bungie.net";
const BUNGIE_ENDPOINT_PGCRS = "https://stats.bungie.net";

const badgeInfo = {
  "Flawless": "Completed with no fireteam deaths.",
  "Solo": "Completed alone.",
  "Duo": "Completed in a fireteam of two (2).",
  "Trio": "Completed in a fireteam of three (3).",
  "Solo Flawless": "Completed alone, with no deaths.",
  "Duo Flawless": "Completed in a fireteam of two (2), with no deaths.",
  "Trio Flawless": "Completed in a fireteam of three (3), with no deaths.",
  "Fresh": "Completed from the very beginning.",
  "Checkpoint": "Completed from a checkpoint. Some activities register this incorrectly in Bungie's API.",
  "Incomplete": "This activity never finished.",
}

const PGCR = () => {
  const PARAMS = useParams();

  // Showing PGCR
  const [players, setPlayers] = useState([]);
  const [team2Players, setTeam2Players] = useState([]);
  const [activityName, setActivityName] = useState("");
  const [activitySubName, setActivitySubName] = useState("");
  const [activityImage, setActivityImage] = useState("");
  const [teams, setTeams] = useState(0);
  const [winTeam, setWinTeam] = useState("");
  const [loseTeam, setLoseTeam] = useState("");

  const [pgcrBadges, setPgcrBadges] = useState([]);

  const [badges, setBadges] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} disableInteractive />
  ))`
    & .MuiTooltip-tooltip {
      color: #f2fffe;
      font-family: var(--font-display);
      letter-spacing: 0.25px;
      outline: 2px solid #fff5;
      background: #0005;
      text-align: center;
      text-shadow: 0px 2px 2px #000;
    }
  `;

  const getPGCR = async (ID) => {
    if (ID === null) return;
    const response = await fetch(`${BUNGIE_ENDPOINT_PGCRS}/Platform/Destiny2/Stats/PostGameCarnageReport/${ID}`, { headers: {"X-API-Key": API_KEY} });
    const item = await response.json();
    const acts = JSON.parse(localStorage.getItem("activityHashes"));
    setTeams(item.Response.teams.length);
    console.log(item);
    if (item.Response.teams.length >= 2){
      var dnfs = item.Response.entries.filter((a) => a.values.completed.basic.value === 0);
      var dnfsAtEnd = item.Response.entries.filter((a) => a.values.completed.basic.value !== 0).concat(dnfs);
      setPlayers(dnfsAtEnd.filter(x => x.standing === 0));
      setTeam2Players(dnfsAtEnd.filter(x => x.standing === 1));
    }
    else{
      var dnfs = item.Response.entries.filter((a) => a.values.completed.basic.value === 0);
      var dnfsAtEnd = item.Response.entries.filter((a) => a.values.completed.basic.value !== 0).concat(dnfs);
      setPlayers(dnfsAtEnd);
    }

    var highestKd = {characterId: null, characterHash: 0, value: -1};
    var highestDeaths = {characterId: null, characterHash: 0, value: 0};
    var highestPrecision = {characterId: null, characterHash: 0, value: -1};

    var newBadges = [];
    var newPgcrBadges = [];
    let sameDeaths = 0;

    item.Response.entries.filter(x => x.standing === 0).forEach((player) => {
      if (player.values.completed.basic.value !== 0){
        const tag = `${player.player.destinyUserInfo.bungieGlobalDisplayNameCode}`;
        const bungieName = `${player.player.destinyUserInfo.bungieGlobalDisplayName}#${tag.padStart(4, '0')}`;
        if (player.values.killsDeathsRatio.basic.value >= highestKd.value) {
          highestKd = {characterId: player.characterId, value: player.values.killsDeathsRatio.basic.value.toFixed(2)};
        }

        if (player.values.deaths.basic.value > highestDeaths.value) {
          highestDeaths = {characterId: player.characterId, value: player.values.deaths.basic.value};
        }

        const precisionRatio = player.extended.values.precisionKills.basic.value / player.values.kills.basic.value;
        if (precisionRatio >= highestPrecision.value){
          highestPrecision = {characterId: player.characterId, value : precisionRatio.toFixed(2)};
        }
      }
    });

    //const deathsSort = item.Response.entries.filter(x => x.standing === 0).sort((a, b) => a.values.deaths.basic.displayValue - b.values.deaths.basic.displayValue).reverse();

    //highestDeaths = {characterId: deathsSort[0].characterId, value: deathsSort[0].values.deaths.basic.value}
    
    const kdBadge = {characterId: highestKd.characterId, badgeName: "Most Efficient"};
    const deathsBadge = {characterId: highestDeaths.characterId, badgeName: "Most Deaths"};
    const precisionBadge = {characterId: highestPrecision.characterId, badgeName: "Most Precise"};
    
    if (item.Response.activityWasStartedFromBeginning === true){
      newPgcrBadges.push({team: 0, badgeName: "Fresh"});
    }
    else{
      newPgcrBadges.push({team: 0, badgeName: "Checkpoint"});
    }
    if (item.Response.entries.filter(x => x.standing === 0).filter(x => x.values.deaths.basic.value == highestDeaths.value).length === 1){
      newBadges.push(deathsBadge);
    }

    let flawlessAdded = false;
    if (item.Response.entries[0].values.completionReason.basic.value !== 0){
      newPgcrBadges.push({team: 0, badgeName: "Incomplete"});
    }
    else if (highestDeaths.value === 0){
      if (item.Response.entries.length === 1){
        newPgcrBadges.push({team: 0, badgeName: "Solo Flawless"});
      }
      else if (item.Response.entries.length === 2){
        newPgcrBadges.push({team: 0, badgeName: "Duo Flawless"});
      }
      else if (item.Response.entries.length === 3 && acts[item.Response.activityDetails.directorActivityHash].maxPlayers === 6){
        newPgcrBadges.push({team: 0, badgeName: "Trio Flawless"});
      }
      else {
        newPgcrBadges.push({team: 0, badgeName: "Flawless"});
      }
    }
    else{
      //console.log(acts[item.Response.activityDetails.directorActivityHash].maxPlayers);
      if (item.Response.entries.length === 1){
        newPgcrBadges.push({team: 0, badgeName: "Solo"});
      }
      else if (item.Response.entries.length === 2){
        newPgcrBadges.push({team: 0, badgeName: "Duo"});
      }
      else if (item.Response.entries.length === 3 && acts[item.Response.activityDetails.directorActivityHash].maxPlayers === 6){
        newPgcrBadges.push({team: 0, badgeName: "Trio"});
      }
    }

    if (item.Response.teams.length >= 2){
      var highestKd = {characterId: null, characterHash: 0, value: -1};
      var highestDeaths = {characterId: null, characterHash: 0, value: 0};
      var highestPrecision = {characterId: null, characterHash: 0, value: -1};

      item.Response.teams.forEach((team) => {
        if (team.standing.basic.value === 0){
          setWinTeam({score: team.score.basic.displayValue, standing: "Victors"});
        }
        else {
          setLoseTeam({score: team.score.basic.displayValue, standing: "Losers"});
        }
      });

      sameDeaths = 0;
      item.Response.entries.filter(x => x.standing === 1).forEach((player) => {
        if (player.values.completed.basic.value !== 0){
          const tag = `${player.player.destinyUserInfo.bungieGlobalDisplayNameCode}`;
          const bungieName = `${player.player.destinyUserInfo.bungieGlobalDisplayName}#${tag.padStart(4, '0')}`;
          if (player.values.killsDeathsRatio.basic.value >= highestKd.value) {
            highestKd = {characterId: player.characterId, value: player.values.killsDeathsRatio.basic.value.toFixed(2)};
          }
  
          if (player.values.deaths.basic.value > highestDeaths.value) {
            highestDeaths = {characterId: player.characterId, value: player.values.deaths.basic.value};
          }
          else if (player.values.deaths.basic.value = highestDeaths.value){
            sameDeaths++;
          }
  
          const precisionRatio = player.extended.values.precisionKills.basic.value / player.values.kills.basic.value;
          if (precisionRatio >= highestPrecision.value){
            highestPrecision = {characterId: player.characterId, value : precisionRatio.toFixed(2)};
          }
        }
      });

      const kdBadge1 = {characterId: highestKd.characterId, badgeName: "Most Efficient"};
      const deathsBadge1 = {characterId: highestDeaths.characterId, badgeName: "Most Deaths"};
      const precisionBadge1 = {characterId: highestPrecision.characterId, badgeName: "Most Precise"};

      if (sameDeaths < item.Response.entries.filter(x => x.standing === 1).length){
        newBadges.push(deathsBadge1);
      }

      newBadges.push(kdBadge1, precisionBadge1);

      newPgcrBadges = [];
    }

    newBadges.push(kdBadge, precisionBadge);

    setActivityName(acts[item.Response.activityDetails.directorActivityHash].actName);
    setActivitySubName(acts[item.Response.activityDetails.referenceId].actSubName);
    setActivityImage(BUNGIE_ENDPOINT+acts[item.Response.activityDetails.referenceId].image);

    document.title = `${acts[item.Response.activityDetails.directorActivityHash].actName} | 
      ${acts[item.Response.activityDetails.referenceId].actSubName} | ${ID}`;

    setBadges(newBadges);
    setPgcrBadges(newPgcrBadges);
  }

  const sortKills = async () => {
    var sorted = players.slice().sort((a, b) => b.values.kills.basic.value - a.values.kills.basic.value);
    var dnfs = sorted.filter((a) => a.values.completed.basic.value === 0).sort((a, b) => b.values.kills.basic.value - a.values.kills.basic.value);
    sorted = sorted.filter((a) => a.values.completed.basic.value !== 0).concat(dnfs);
    setPlayers(sorted);
    if (teams >= 2){
      const sorted = team2Players.slice().sort((a, b) => b.values.kills.basic.value - a.values.kills.basic.value);
      setTeam2Players(sorted);
    }
  }

  const sortDeaths = async () => {
    var sorted = players.slice().sort((a, b) => a.values.deaths.basic.displayValue - b.values.deaths.basic.displayValue);
    var dnfs = sorted.filter((a) => a.values.completed.basic.value === 0).sort((a, b) => a.values.deaths.basic.displayValue - b.values.deaths.basic.displayValue);
    sorted = sorted.filter((a) => a.values.completed.basic.value !== 0).concat(dnfs);
    setPlayers(sorted);
    if (teams >= 2){
      const sorted = team2Players.slice().sort((a, b) => a.values.deaths.basic.displayValue - b.values.deaths.basic.displayValue)
      setTeam2Players(sorted);
    }
  }

  const sortKD = async () => {
    var sorted = players.slice().sort((a, b) => b.values.killsDeathsRatio.basic.value - a.values.killsDeathsRatio.basic.value);
    var dnfs = sorted.filter((a) => a.values.completed.basic.value === 0).sort((a, b) => b.values.killsDeathsRatio.basic.value - a.values.killsDeathsRatio.basic.value);
    sorted = sorted.filter((a) => a.values.completed.basic.value !== 0).concat(dnfs);
    setPlayers(sorted);
    if (teams >= 2){
      const sorted = team2Players.slice().sort((a, b) => b.values.killsDeathsRatio.basic.value - a.values.killsDeathsRatio.basic.value);
      setTeam2Players(sorted);
    }
  }

  /*const getManifest = async () => {
    await fetch(`${BUNGIE_ENDPOINT}/Platform/Destiny2/Manifest`, { headers: {"X-API-Key": API_KEY} })
      .then(response => response.json())
      .then(item => fetch(`${BUNGIE_ENDPOINT}${item.Response.jsonWorldComponentContentPaths.en["DestinyActivityDefinition"]}`))
      .then(response => response.json())
      .then(item => {
        setActivityHashes(item);
      })
      .then(setActivityName(activityHashes[activityHash].displayProperties.name));
  }*/

  useEffect(() => {
    //getManifest();
    getPGCR(PARAMS.pgcrId);
  }, []);

  function TeamPgcr() {
    return <>
    <div className="top-container">
      <div className="title-container">
        <h2 className="name">{activityName.toUpperCase()}</h2>
        <h3 className="sub-name">{activitySubName}</h3>
        <div className="rectangle" />
      </div>
      <div className="button-container">
        <p>Sort by</p>
        <br></br>
        <button id="sort-kills" onClick={sortKills}>Most Kills</button>
        <button id="sort-deaths" onClick={sortDeaths}>Least Deaths</button>
        <button id="sort-kd" onClick={sortKD}>Highest K/D Ratio</button>
      </div>
    </div>
    <div className="pgcr-container">
      <div className="column-team-1" key="1">
        <div className="team-details">
          <h2>{winTeam.standing}</h2>
          <h2>{winTeam.score}</h2>
        </div>
        <div className="pgcr-badges">
          {pgcrBadges.filter(x => x.team === 0).map((badge) => (
            <div className="badge">
              <span id={badge.badgeName}>{badge.badgeName}</span>
            </div>
          ))}
        </div>
        {players.map((player) => (
          <PGCRPlayerCard player={player} badges={badges} />
        ))}
      </div>
      <div className="column-team-2" key="2">
        <div className="team-details">
          <h2>{loseTeam.standing}</h2>
          <h2>{loseTeam.score}</h2>
        </div>
        <div className="pgcr-badges">
          {pgcrBadges.filter(x => x.team === 1).map((badge) => (
            <div className="badge">
              <span>{badge.badgeName}</span>
            </div>
          ))}
        </div>
        {team2Players.map((player) => (
          <PGCRPlayerCard player={player} badges={badges} />
        ))}
      </div>
    </div>
    </>
  }

  function NormalPgcr() {
    return <>
    <div className="top-container">
      <div className="title-container">
        <h2 className="name">{activityName.toUpperCase()}</h2>
        <h3 className="sub-name">{activitySubName}</h3>
        <div className="rectangle" />
      </div>
      <div className="button-container">
        <p>Sort by</p>
        <br></br>
        <button id="sort-kills" onClick={sortKills}>Most Kills</button>
        <button id="sort-deaths" onClick={sortDeaths}>Least Deaths</button>
        <button id="sort-kd" onClick={sortKD}>Highest K/D Ratio</button>
      </div>
    </div>
    <div className="pgcr-badges">
      {pgcrBadges.map((badge) => (
        <StyledTooltip title={badgeInfo[badge.badgeName]} placement="top">
          <div className="badge">
            <span>{badge.badgeName}</span>
          </div>
        </StyledTooltip>
      ))}
      
    </div>
    <div className="pgcr-container">
      {players.length > 3 ?
        <><div className="column" key="1">
          {players.slice(0, Math.ceil(players.length / 2)).map((player) => (
            <PGCRPlayerCard player={player} badges={badges} />
          ))}
        </div><div className="column" key="2">
          {players.slice(Math.ceil(players.length / 2), players.length).map((player) => (
            <PGCRPlayerCard player={player} badges={badges} />
          ))}
        </div></>
          :
        <div className="column" key="1">
          {players.map((player) => (
            <PGCRPlayerCard player={player} badges={badges} />
          ))}
        </div>
      }
    </div></>
  }

  function RenderPgcr() {
    if (teams >= 2){
      return <TeamPgcr />;
    } else{
      return <NormalPgcr />;
    }
  }

  return (
    <div className="app" style={{"backgroundImage":`linear-gradient(to bottom, #14141445, #141414), url(\"${activityImage}\")`}}>
      <div className="head">
        <h1>OatsFX's PGCR Viewer</h1>
      </div>
      <RenderPgcr />
    </div>
  );
}

export default PGCR;
