import React, { useEffect, useState } from 'react';
import warlock from './images/Warlock.png';
import hunter from './images/Hunter.png';
import titan from './images/Titan.png';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const BUNGIE_ENDPOINT = "https://www.bungie.net";

const CHAR_HASHES = {
  2271682572: warlock,
  671679327: hunter,
  3655393761: titan,
}

const badgeInfo = {
  "Most Efficient": "This player had the best efficiency (K/D Ratio).",
  "Most Precise": "This player had the best precision kills to kills ratio.",
  "Most Deaths": "This player died the most.",
  "DNF": "This player Did Not Finish the activity.",
  "Site Developer": "This player developed this site.",
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} disableInteractive />
))`
  & .MuiTooltip-tooltip {
    color: #f2fffe;
    font-family: var(--font-display);
    letter-spacing: 0.25px;
    outline: 2px solid #fff5;
    background: #0005;
    text-align: center;
    text-shadow: 0px 2px 2px #000;
  }
`;

const PGCRPlayerCard = ({ player, badges }) => {
  useEffect(() => {
    const cardDiv = document.getElementById(player.characterId);
    if (player.values.completed.basic.value === 0){
      cardDiv.classList.add('pgcr-player-dnf');
    }
  }, []);
  console.log(player);
  const tag = `${player.player.destinyUserInfo.bungieGlobalDisplayNameCode}`;
  const bungieName = `${player.player.destinyUserInfo.bungieGlobalDisplayName}#${tag.padStart(4, '0')}`;
  return (
      <div className="pgcr-player" id={player.characterId}>
        <img src={CHAR_HASHES[player.player.classHash]} className="class-icon"></img>
        <img src={BUNGIE_ENDPOINT+player.player.destinyUserInfo.iconPath} className="emblem"></img>
        
        <div className="details">
          <h3>{player.player.destinyUserInfo.bungieGlobalDisplayName}<span className="bungie-tag">#{tag.padStart(4 ,'0')}</span> <span className="light">{player.player.lightLevel}</span></h3>
        </div>

        <div className="badges">

          {badges.map((badge) => (
            badge.characterId === player.characterId ?
            <StyledTooltip title={badgeInfo[badge.badgeName]} placement="right">
              <div className="badge">
                <span>{badge.badgeName}</span>
              </div>
            </StyledTooltip> : []
          ))}

          {
            player.values.completed.basic.value === 0 ? 
            <StyledTooltip title={badgeInfo["DNF"]} placement="right">
              <div className="badge">
                <span className="dnf">DNF</span>
              </div>
            </StyledTooltip> : []
          }

          {
            bungieName === "OatsFX#5630" ? 
            <StyledTooltip title={badgeInfo["Site Developer"]} placement="right">
              <div className="badge">
                <span className="dev">Site Developer</span>
              </div>
            </StyledTooltip> : []
          }
          
        </div>

        <div className="stats">
          <div className="stat">
            <p>{player.values.kills.basic.value}</p><p className="stat-name">KILLS</p>
          </div>
          <div className="stat">
            <p>{player.values.deaths.basic.displayValue}</p><p className="stat-name">DEATHS</p>
          </div>
          <div className="stat">
            <p>{player.values.killsDeathsRatio.basic.value.toFixed(2)}</p><p className="stat-name">K/D RATIO</p>
          </div>
          <div className="stat">
            <p>{player.values.activityDurationSeconds.basic.displayValue}</p><p className="stat-name">TIME</p>
          </div>
        </div>
      </div>
  )
}
export default PGCRPlayerCard;